import { captureMessage, captureException, addBreadcrumb, setUser, configureScope } from '@sentry/browser'
import Base64 from 'crypto-js/enc-base64'
import SHA256 from 'crypto-js/sha256'
import jwtDecode from 'jwt-decode'
import { parse } from 'query-string'

import { commonSpellingErrors } from './spelling'

function validateRedirectUrl(url: string) {
  let parsedUrl

  try {
    parsedUrl = new URL(url)
  } catch (e) {
    return false
  }

  if (url.startsWith('http://localhost:300')) {
    return true
  }

  if (parsedUrl.protocol !== 'https:') {
    return false
  }

  if (parsedUrl.hostname.endsWith('.northvolt.com') || parsedUrl.hostname.endsWith('.northvolt.dev') || parsedUrl.hostname.endsWith('.nvlt.co')) {
    return true
  }

  return false
}

const loadProviders = () => {
  if (process.env.REACT_APP_PROJECT === 'automation') {
    return [
      {
        domain: 'northvolt.com',
        id: 'Northvolt',
      },
      {
        domain: 'northvolt.pl',
        id: 'Northvolt',
      },
      {
        domain: 'nv-external.com',
        id: 'Northvolt',
      },
      {
        domain: 'northvolt0.onmicrosoft.com',
        id: 'Northvolt',
      },
      {
        domain: '@',
        id: 'COGNITO',
      },
    ]
  }

  if (process.env.REACT_APP_PROJECT === 'revolt') {
    return [
      {
        domain: 'northvolt.com',
        id: 'Northvolt',
      },
      {
        domain: 'domain.tld',
        id: 'COGNITO',
      },
    ]
  }

  return [
    {
      domain: 'northvolt.com',
      id: 'Northvolt',
    },
    {
      domain: 'epiroc.com',
      id: 'Epiroc',
    },
    {
      domain: 'meglab.ca',
      id: 'Epiroc',
    },
    {
      domain: 'vestas.com',
      id: 'Vestas',
    },
    {
      domain: 'nv-external.com',
      id: 'Northvolt',
    },
    {
      domain: 'uniper.energy',
      id: 'Uniper',
    },
    {
      domain: 'cuberg.net',
      id: 'Northvolt',
    },
    {
      domain: 'konecranes.com',
      id: 'Konecranes',
    },
    {
      domain: 'pon-cat.com',
      id: 'Poncat',
    },
    {
      domain: 'ponpepp.com',
      id: 'Poncat',
    },
    {
      domain: 'dynell.at',
      id: 'Dynell',
    },
    {
      domain: 'domain.tld',
      id: 'COGNITO',
    },
  ]
}

const generateState = (passwordLength: number) => {
  let result = ''
  let hashLength = passwordLength
  const chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'

  for (hashLength > 0; --hashLength;) {
    result += chars[Math.round(Math.random() * (chars.length - 1))]
  }

  return result
}

const bufferToString = (buffer: Uint8Array) => {
  const CHARSET = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  const state = []

  for (let i = 0; i < buffer.byteLength; i += 1) {
    const index = buffer[i] % CHARSET.length

    state.push(CHARSET[index])
  }

  return state.join('')
}

const generateHashBuffer = (size: number) => {
  const CHARSET = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-._~'
  const buffer = new Uint8Array(size)

  if (typeof window !== 'undefined' && Boolean(window.crypto)) {
    window.crypto.getRandomValues(buffer)
  } else {
    for (let i = 0; i < size; i += 1) {
      buffer[i] = Math.random() * CHARSET.length || 0
    }
  }

  return bufferToString(buffer)
}

const base64URL = (base: string) => {
  // @ts-ignore
  return Base64.stringify(base).replace(/[=]/g, '').replace(/\+/g, '-').replace(/\//g, '_')
}

const generateChallenge = (code: string) => {
  // @ts-ignore
  return base64URL(SHA256(code))
}

const getProvider = (mail: string) => {
  const supportedProviders = loadProviders()

  for (const provider of supportedProviders) {
    if (mail.indexOf(provider.domain) > -1) {
      return provider
    }
  }

  captureMessage(`provided email: ${mail} is not supported yet`)
  throw new Error(`provided email: ${mail} is not supported yet`)
}

function createFormData(payload: object): string {
  return Object.keys(payload).map((key) => {
    // @ts-ignore
    return `${encodeURIComponent(key)}=${encodeURIComponent(payload[key])}`
  }).join('&')
}

const postFormData = async (endpoint: string, payload: any) => {
  return fetch(`https://${process.env.REACT_APP_OAUTH_DOMAIN}/${endpoint}`, {
    body: createFormData(payload),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    method: 'POST',
  })
    .then((response) => response.json())
    .then((responseData) => {
      if (responseData?.error) {
        captureException(responseData.error)
        throw responseData.error
      }

      return responseData
    })
}

const getCookieDomain = () => {
  if (process.env.REACT_APP_ENVIRONMENT === 'local') {
    return 'localhost'
  }

  const urlPartials = window.location.host.split('.').reverse()
  const rootDomain = `.${urlPartials[1]}.${urlPartials[0]}`

  if (process.env.REACT_APP_TP_DEV === 'true') {
    return `.tp-dev${rootDomain}`
  }

  if (process.env.REACT_APP_ENVIRONMENT === 'development') {
    return `.dev${rootDomain}`
  }

  if (process.env.REACT_APP_TP_PROD === 'true') {
    return `.tp${rootDomain}`
  }

  // This is to solve for the subdomain issue for .site.nvlt.co which previously got returned as .nvlt.co
  // We have CF-extra lambdas that refresh for the subdomain .site.nvlt.co and not .nvlt.co
  if (urlPartials.length > 3) {
    return `.${urlPartials[2]}.${urlPartials[1]}.${urlPartials[0]}`
  }

  if (process.env.REACT_APP_LOG_EVERYTHING) {
    addBreadcrumb({
      category: 'auth',
      level: 'info',
      message: 'getCookieDomain',
      data: {
        rootDomain,
      },
    })
    captureMessage('getCookieDomain', 'info')
  }

  return rootDomain
}

function setCookie(name: string, value: string, expiration: Date, domain: string) {
  const cookieSecurity = process.env.REACT_APP_ENVIRONMENT === 'local' ? '' : 'secure'

  if (process.env.REACT_APP_LOG_EVERYTHING) {
    addBreadcrumb({
      category: 'auth',
      level: 'info',
      message: 'setCookie',
      data: {
        name,
        value,
        expiration,
        domain,
        cookieSecurity,
      },
    })
    captureMessage('setCookie', 'info')
  }

  return (document.cookie = `${name}=${value};domain=${domain};path=/;${cookieSecurity}`)
}

function getCookie(name: string): string {
  const nameEQ = `${name}=`
  const cookies = document.cookie.split(';')

  for (let cookie of cookies) {
    if (process.env.REACT_APP_LOG_EVERYTHING) {
      addBreadcrumb({
        category: 'auth',
        level: 'info',
        message: 'foundCookieOmNom',
        data: {
          name,
          cookie,
        },
      })
    }

    while (cookie.charAt(0) === ' ') {
      cookie = cookie.substring(1, cookie.length)
    }

    if (cookie.indexOf(nameEQ) === 0) {
      return cookie.substring(nameEQ.length, cookie.length)
    }
  }

  if (process.env.REACT_APP_LOG_EVERYTHING) {
    captureMessage('getCookie is empty', 'info')
  }

  return ''
}

function eraseCookie(cookieName: string) {
  const cookieDomain = getCookieDomain()

  return (document.cookie = `${cookieName}=;Max-Age=-99999999;domain=${cookieDomain}`)
}

function checkSpelling(inputMail: string) {
  let mail = inputMail.toLowerCase()

  for (const missSpelling of commonSpellingErrors) {
    if (mail.indexOf(missSpelling) > -1) {
      mail = mail.replace(missSpelling, 'northvolt.com')

      return mail
    }
  }

  if (mail.indexOf('@northvolt') > -1) {
    if (mail.indexOf('.com') === -1) {
      if (mail[mail.length - 1] === 't') {
        mail = `${mail}.com`
      }
    }
  }

  if (process.env.REACT_APP_LOG_EVERYTHING) {
    addBreadcrumb({
      category: 'auth',
      level: 'info',
      message: 'checkSpelling',
      data: {
        mail,
      },
    })
    captureMessage('checkSpelling', 'info')
  }

  return mail
}

function login(inputMail: string) {
  // https://docs.sentry.io/platforms/javascript/enriching-events/identify-user/
  setUser({
    ip_address: '{{ auto }}',
  })

  addBreadcrumb({
    category: 'auth',
    level: 'info',
    message: 'login started',
  })

  if (process.env.REACT_APP_LOG_EVERYTHING) {
    captureMessage(`Login with user mail ${inputMail}`, 'info')
  }

  const mail = checkSpelling(inputMail)

  const provider = getProvider(mail)

  if (!provider) {
    captureMessage(`provided email: ${mail} is not supported yet`)
    throw new Error('provided email is not supported yet')
  }

  if (process.env.REACT_APP_LOG_EVERYTHING) {
    addBreadcrumb({
      category: 'auth',
      level: 'info',
      message: 'login',
      data: {
        provider,
      },
    })
    captureMessage('checkSpelling', 'info')
  }

  if (process.env.REACT_APP_LOG_EVERYTHING) {
    captureMessage(`${mail} has provider ${provider}`, 'info')
  }

  const authState = generateState(32)

  // Verify that window.sessionStorage is available
  if (!window.sessionStorage) {
    captureMessage('Session Storage is not available on this device!')
    throw new Error('session storage is not available on this device')
  }

  if (process.env.REACT_APP_LOG_EVERYTHING) {
    captureMessage(`${mail} has session storage`, 'info')
  }

  window.sessionStorage.setItem('authState', authState)

  const codeChallengeMethod = 'S256'
  const codeKey = generateHashBuffer(128)

  window.sessionStorage.setItem('codeVerifier', codeKey)

  const codeChallenge = generateChallenge(codeKey)

  // Verify that we can read from window.sessionStorage
  const sessionStorageData = {
    authState: window.sessionStorage.getItem('authState'),
    codeVerifier: window.sessionStorage.getItem('codeVerifier'),
  }

  window.history.pushState('', '', '/')

  const url = `https://${process.env.REACT_APP_OAUTH_DOMAIN}/oauth2/authorize?identity_provider=${provider.id}&redirect_uri=${window.location.href}&response_type=CODE&client_id=${process.env.REACT_APP_COGNITO_USER_POOL_WEB_CLIENT_ID}&state=${authState}&code_challenge_method=${codeChallengeMethod}&code_challenge=${codeChallenge}&scope=aws.cognito.signin.user.admin%20email%20openid%20profile`

  addBreadcrumb({
    category: 'auth',
    level: 'info',
    message: 'redirect',
    data: {
      url,
      sessionStorageData,
    },
  })

  if (process.env.REACT_APP_LOG_EVERYTHING) {
    captureMessage(`${mail} url: ${url}`, 'info')
  }

  if (!sessionStorageData.authState || !window.sessionStorage.codeVerifier) {
    captureMessage('window.sessionStorage fail')
    throw new Error('failed to store authentication details in browser storage')
  }

  if (process.env.REACT_APP_LOG_EVERYTHING) {
    captureMessage(`${mail} redirect to AWS works`, 'info')
  }

  window.location.assign(url)
}

const exchangeToken = async (code: string) => {
  const codeVerifier = window.sessionStorage.getItem('codeVerifier')

  addBreadcrumb({
    category: 'auth',
    level: 'info',
    message: 'exchangeToken',
  })

  const payload: any = {
    client_id: process.env.REACT_APP_COGNITO_USER_POOL_WEB_CLIENT_ID,
    code,
    code_verifier: codeVerifier,
    grant_type: 'authorization_code',
    redirect_uri: `${window.location.origin}/`,
  }

  const { refresh_token, access_token, id_token } = await postFormData('oauth2/token', payload)

  window.sessionStorage.removeItem('codeVerifier')

  if (!refresh_token || !access_token || !id_token) {
    captureMessage('missing payload from token exchange, this is an authentication error!')
    throw new Error('missing payload from token exchange, this is an authentication error!')
  }

  try {
    // @ts-ignore
    if (window.ReactNativeWebView) {
      // @ts-ignore
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          access_token,
          id_token,
          refresh_token,
          cookie_prefix: process.env.REACT_APP_COOKIE_PREFIX,
        })
      )
    }
  } catch (error) {
    captureException(error)
    throw error
  }

  try {
    // @ts-ignore
    if (window.webkit?.messageHandlers?.auth) {
      // @ts-ignore
      window.webkit.messageHandlers.auth.postMessage({
        access_token,
        id_token,
        refresh_token,
        cookie_prefix: process.env.REACT_APP_COOKIE_PREFIX,
      })
    }
  } catch (error) {
    captureException(error)
    throw error
  }

  const expirationDate = new Date()

  expirationDate.setMonth(expirationDate.getMonth() + 1)

  const idTokenExpirationdate = new Date()

  idTokenExpirationdate.setHours(idTokenExpirationdate.getHours() + 0.5)

  const cookieDomain = getCookieDomain()

  setCookie(
    `${process.env.REACT_APP_COOKIE_PREFIX}refresh`,
    refresh_token,
    expirationDate,
    cookieDomain
  )
  setCookie(
    `${process.env.REACT_APP_COOKIE_PREFIX}access`,
    access_token,
    expirationDate,
    cookieDomain
  )
  setCookie(
    `${process.env.REACT_APP_COOKIE_PREFIX}id`,
    id_token,
    idTokenExpirationdate,
    cookieDomain
  )

  window.history.pushState('', '', '/')

  return true
}

const getUserData = () => {
  try {
    const idToken = getCookie(`${process.env.REACT_APP_COOKIE_PREFIX}id`)
    const refreshToken = getCookie(`${process.env.REACT_APP_COOKIE_PREFIX}refresh`)
    const idData: any = jwtDecode(idToken)

    configureScope((scope) => {
      scope.setUser({
        email: idData.email,
        username: `${idData.given_name} ${idData.family_name}`,
      })
      scope.setTag('tenant', idData['custom:tenant'])
      scope.setTag('roles', idData['custom:roles'])
    })

    const userData = {
      email: idData.email,
      firstName: idData.given_name,
      fullName: `${idData.given_name} ${idData.family_name}`,
      lastName: idData.family_name,
      roles: idData['custom:roles'],
      tenant: idData['custom:tenant'],
      jobTitle: idData['custom:jobtitle'],
      idToken,
      refreshToken,
    }

    if (process.env.REACT_APP_LOG_EVERYTHING) {
      addBreadcrumb({
        category: 'auth',
        level: 'info',
        message: 'getUserData',
        data: {
          userData,
        },
      })
      captureMessage('got userData', 'info')
    }

    if (process.env.REACT_APP_ENVIRONMENT === 'production') {
      eraseCookie(`${process.env.REACT_APP_COOKIE_PREFIX}dev_access`)
      eraseCookie(`${process.env.REACT_APP_COOKIE_PREFIX}dev_id`)
      eraseCookie(`${process.env.REACT_APP_COOKIE_PREFIX}dev_refresh`)
    }

    return userData
  } catch (error) {
    if (process.env.REACT_APP_LOG_EVERYTHING) {
      addBreadcrumb({
        category: 'auth',
        level: 'info',
        message: 'getUserData',
        data: {
          error,
        },
      })
      captureMessage('failed to decode jwt from cookie', 'info')
    }

    return false
  }
}

const refreshToken = async (suppliedToken?: string) => {
  if (process.env.REACT_APP_LOG_EVERYTHING) {
    captureMessage('refresh token', 'info')
  }

  const token = suppliedToken
    ? suppliedToken
    : getCookie(`${process.env.REACT_APP_COOKIE_PREFIX}refresh`)

  if (!token) {
    addBreadcrumb({
      category: 'auth',
      level: 'info',
      message: 'user is logged out',
    })

    throw new Error('')
  }

  if (process.env.REACT_APP_LOG_EVERYTHING) {
    addBreadcrumb({
      category: 'auth',
      level: 'info',
      message: 'refreshToken',
      data: {
        token,
      },
    })

    captureMessage('refresh token prefered', 'info')
  }

  const payload: any = {
    client_id: process.env.REACT_APP_COGNITO_USER_POOL_WEB_CLIENT_ID,
    grant_type: 'refresh_token',
    refresh_token: token,
  }
  const { access_token, id_token } = await postFormData('oauth2/token', payload)

  if (!access_token || !id_token) {
    addBreadcrumb({
      category: 'auth',
      level: 'info',
      message: 'missing payload from refreshToken, login is no longer valid',
    })

    throw new Error('missing payload from refreshToken, login is no longer valid')
  }

  if (process.env.REACT_APP_LOG_EVERYTHING) {
    addBreadcrumb({
      category: 'auth',
      level: 'info',
      message: 'refreshToken',
      data: {
        id_token,
      },
    })
    captureMessage('got a new id_token', 'info')
  }

  try {
    // @ts-ignore
    if (window.ReactNativeWebView && !suppliedToken) {
      // @ts-ignore
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          access_token,
          id_token,
          refresh_token: token,
        })
      )
    }
  } catch (error) {
    captureException(error)
    throw error
  }

  try {
    // @ts-ignore
    if (window.webkit?.messageHandlers?.auth && !suppliedToken) {
      // @ts-ignore
      window.webkit.messageHandlers.auth.postMessage({
        access_token,
        id_token,
        refresh_token: token,

      })
    }
  } catch (error) {
    captureException(error)
    throw error
  }

  const expirationDate = new Date()

  expirationDate.setMonth(expirationDate.getMonth() + 1)

  const idTokenExpirationdate = new Date()

  idTokenExpirationdate.setHours(idTokenExpirationdate.getHours() + 1)

  const cookieDomain = getCookieDomain()

  if (process.env.REACT_APP_LOG_EVERYTHING) {
    addBreadcrumb({
      category: 'auth',
      level: 'info',
      message: 'refreshToken',
      data: {
        cookieDomain,
        idTokenExpirationdate,
      },
    })
    captureMessage('set cookie domain', 'info')
  }

  if (suppliedToken) {
    setCookie(
      `${process.env.REACT_APP_COOKIE_PREFIX}refresh`,
      suppliedToken,
      expirationDate,
      cookieDomain
    )
  }

  setCookie(
    `${process.env.REACT_APP_COOKIE_PREFIX}access`,
    access_token,
    expirationDate,
    cookieDomain
  )
  setCookie(
    `${process.env.REACT_APP_COOKIE_PREFIX}id`,
    id_token,
    idTokenExpirationdate,
    cookieDomain
  )

  if (process.env.REACT_APP_LOG_EVERYTHING) {
    captureMessage('refresh token set to cookies', 'info')
  }

  return true
}

const authenticate = async () => {
  addBreadcrumb({
    category: 'auth',
    level: 'info',
    message: 'authenticate user',
  })

  const params: any = parse(window.location.search)

  addBreadcrumb({
    category: 'auth',
    level: 'info',
    message: 'authenticate params',
    data: {
      params,
    },
  })

  if (process.env.REACT_APP_LOG_EVERYTHING) {
    captureMessage('authenticate user', 'info')
  }

  if (params.error) {
    captureMessage('authenticate params.error', params.error.message)

    if (params.error_description) {
      captureMessage(params.error_description)
    }
    throw new Error(params.error_description)
  }

  if (params.error_description) {
    captureMessage(params.error_description)
    throw new Error(params.error_description)
  }

  addBreadcrumb({
    category: 'auth',
    level: 'info',
    message: 'authenticate redirect',
    data: {
      url: params.redirect_url,
    },
  })

  if (process.env.REACT_APP_LOG_EVERYTHING) {
    captureMessage(`authenticate redirect ${params.redirect_url}`, 'info')
  }

  if (!window.sessionStorage) {
    captureMessage('Session Storage is not available on this device!')
  }

  if (params.encoded_redirect_url) {
    const decoded_redirect_url = decodeURIComponent(params.encoded_redirect_url)
    const isValidUrl = validateRedirectUrl(decoded_redirect_url)

    if (isValidUrl) {
      window.sessionStorage.setItem('redirect', decoded_redirect_url)
    }

    window.history.pushState('', '', '/')
  } else if (params.redirect_url) {
    const isValidUrl = validateRedirectUrl(params.redirect_url)

    if (isValidUrl) {
      window.sessionStorage.setItem('redirect', params.redirect_url)
    }

    window.history.pushState('', '', '/')
  }

  if (params.code) {
    const authState = window.sessionStorage.getItem('authState')

    addBreadcrumb({
      category: 'auth',
      level: 'info',
      message: 'session storage miss-match',
      data: {
        stateFromAuthState: authState,
        stateFromParams: params.state,
      },
    })

    if (process.env.REACT_APP_LOG_EVERYTHING) {
      captureMessage(`authenticate code exchange ${params.code}`, 'info')
    }

    if (params.state !== authState) {
      captureMessage('invalid redirect session ')
      throw new Error('invalid redirect session')
    }

    window.sessionStorage.removeItem('authState')
    await exchangeToken(params.code)
  }

  if (process.env.REACT_APP_LOG_EVERYTHING) {
    captureMessage('authenticate refresh token started', 'info')
  }

  await refreshToken()

  const storedRedirect = window.sessionStorage.getItem('redirect')

  if (process.env.REACT_APP_LOG_EVERYTHING) {
    captureMessage('authenticate refresh token worked redirecring..', 'info')
  }

  if (storedRedirect) {
    //window.sessionStorage.removeItem('redirect')
    window.location.assign(storedRedirect.replace(/[-?]\s*$/, ''))

    return true
  }

  return false
}

const logout = () => {
  addBreadcrumb({
    category: 'auth',
    level: 'info',
    message: 'logout',
  })

  if (process.env.REACT_APP_LOG_EVERYTHING) {
    addBreadcrumb({
      category: 'auth',
      level: 'info',
      message: 'logout',
    })
    captureMessage('logout user', 'info')
  }

  eraseCookie(`${process.env.REACT_APP_COOKIE_PREFIX}access`)
  eraseCookie(`${process.env.REACT_APP_COOKIE_PREFIX}id`)
  eraseCookie(`${process.env.REACT_APP_COOKIE_PREFIX}refresh`)

  window.sessionStorage.clear()

  return window.location.assign(
    'https://login.microsoftonline.com/common/wsfederation?wa=wsignout1.0'
  )
}

export { login, authenticate, getUserData, logout, postFormData, refreshToken, getCookie, validateRedirectUrl }
